import {
  fbGetPages,
  fbGetGroups,
  getPagesLiveVideos,
  GetFbToken,
  fbGetEvents,
  fbUserLiveVideos,
  fbGetCrossPostPages,
  fbPostSponsor,
  fbGetLiveVideos,
  fbGetLocation,
  GetContentTag,
  fbGetFriendList,
  fbAdTags,
  fbCrossPages,
  initCrossPages,
  GetPostComments,
  GetPostReactions,
  fbAdGeolocation,
  GetPost,
  createLiveVideo,
  updateLiveVideo,
  GetLiveVideoStatus,
  UpdateLiveVideoStatus,
  GetVideoStatus,
  fbRefreshToken,
  getFacebookUserDetails,
  fbGetGroupByPage,
  fbGetPageToken,
} from '../../api/fb.api';
import { ImageUploadS3 } from '../../api/aws.api';
import { getLUCUserDetails } from '../../api/luc.api';
import { initFBSdk } from '../fbSdk.service';
import { notify } from '../../components/CommonNotification/CommonNotification';
import i18n from 'i18next';
import Cookies from 'universal-cookie';
import { getLocalStorageItem } from '../../utils/cookieAndLocalStorageUtils';
const cookies = new Cookies();

export const fbService = {
  fbLogin,
  fbEventSubscribe,
  fbLoginStatus,
  fbLogout,
  fbgetPages,
  fbgetGroups,
  fbgetEvents,
  fbuserLiveVideos,
  fbgetLiveVideos,
  userInfo,
  fbGetLocations,
  fbGetFriends,
  fbAdinterest,
  fbGetCrossPage,
  fbPostCrossPages,
  fbGetAdLocations,
  fbPostSponsors,
  fbCreateLiveVideo,
  fbUpdateLiveVideo,
  fbGetLiveVideoStatus,
  fbUpdateLiveVideoStatus,
  fbGetPostViews,
  fbGetPostComments,
  fbGetVideoStatus,
  fbGetPostReactions,
  fbGetContentTag,
  fbGetCrossPosts,
  fbgetToken,
  pagesLiveVideos,
  fbrefreshroken,
  fbGetUserDetails,
  fbGetGeoupByPageId,
  fbGetPageTokenByPageId,
  fbImageUpload,
};

const fbScopes =
  'user_friends,email,public_profile,publish_video,user_videos,publish_to_groups,user_events,pages_read_engagement,pages_show_list,pages_read_user_content,pages_manage_posts,pages_manage_metadata';

function fbEventSubscribe() {
  try {
    // eslint-disable-next-line no-undef
    FB.Event.subscribe('auth.authResponseChange', function (res) {
      if (res.status === 'connected') {
        return res;
      }
    });
  } catch (e) {
    if (e.message.toLowerCase().includes('not defined')) {
      initFBSdk();
    } else {
      Promise.reject(e);
    }
  }
}
function fbLoginStatus() {
  return new Promise((resolve, reject) => {
    try {
      // eslint-disable-next-line no-undef
      FB.getLoginStatus(function (response) {
        if (response.status === 'connected') {
          resolve(response);
        } else {
          reject(response);
        }
      }, true);
    } catch (e) {
      if (e.message.toLowerCase().includes('not defined')) {
        initFBSdk();
      } else {
        reject(e);
      }
    }
  });
}
function fbLogin() {
  return new Promise((resolve, reject) => {
    try {
      // eslint-disable-next-line no-undef
      FB.login(
        function (response) {
          if (response) {
            if (response.authResponse) {
              resolve(response);
            } else {
              reject(response);
            }
          }
        },
        { scope: fbScopes },
      );
    } catch (e) {
      if (e.message.toLowerCase().includes('not defined')) {
        notify('warning', i18n.t('FBFAIL'));
        initFBSdk();
      } else {
        reject(e);
      }
    }
  });
}
async function fbrefreshroken(token) {
  try {
    const headers = {
      Authorization: 'Bearer ' + getLocalStorageItem('access_token'),
      'x-user-uuid': `${cookies.get('user_session')}`,
    };
    return await fbRefreshToken(headers, token);
  } catch (e) {}
}
function fbLogout() {
  return new Promise((resolve, reject) => {
    try {
      // eslint-disable-next-line no-undef
      FB.logout(function () {});
      resolve(true);
    } catch (e) {
      if (e.message.toLowerCase().includes('not defined')) {
        notify('warning', i18n.t('FBFAIL'));
        initFBSdk();
      } else {
        reject(e);
      }
    }
  });
}
async function pagesLiveVideos(id, token, endpoint) {
  try {
    const fbgetPages = await getPagesLiveVideos(id, token, endpoint);
    return fbgetPages.data.data;
  } catch (e) {
    return Promise.reject(e);
  }
}
async function fbgetPages() {
  try {
    const fbgetPages = await fbGetPages();
    return fbgetPages.data;
  } catch (e) {
    return Promise.reject(e);
  }
}
async function fbgetGroups(nextPage) {
  try {
    const fbgetGroups = await fbGetGroups(nextPage);
    return fbgetGroups?.data;
  } catch (e) {
    return Promise.reject(e);
  }
}

async function fbgetToken(id) {
  try {
    const v1 = await GetFbToken(id);
    return v1.data;
  } catch (e) {
    return Promise.reject(e);
  }
}

async function fbPostSponsors(id) {
  try {
    const fbData = await fbPostSponsor(id);
    return fbData.data;
  } catch (e) {
    return Promise.reject(e);
  }
}
async function fbGetVideoStatus(id) {
  try {
    const fbData = await GetVideoStatus(id);
    return fbData.data;
  } catch (e) {
    return Promise.reject(e);
  }
}

async function fbGetContentTag(id) {
  try {
    const fbData = await GetContentTag(id);
    return fbData.data;
  } catch (e) {
    return Promise.reject(e);
  }
}

async function fbGetCrossPosts(id, token) {
  try {
    const fbData = await fbGetCrossPostPages(id, token);
    return fbData.data;
  } catch (e) {
    return Promise.reject(e);
  }
}

async function fbgetEvents() {
  try {
    const fbgetEvents = await fbGetEvents();
    return fbgetEvents.data;
  } catch (e) {
    return Promise.reject(e);
  }
}
async function fbuserLiveVideos(token) {
  try {
    const fbuserLiveVideos = await fbUserLiveVideos(token);
    return fbuserLiveVideos.data;
  } catch (e) {
    return Promise.reject(e);
  }
}
async function fbgetLiveVideos(id, token) {
  try {
    const fbgetLiveVideos = await fbGetLiveVideos(id, token);
    return fbgetLiveVideos.data;
  } catch (e) {
    return Promise.reject(e);
  }
}
async function userInfo() {
  try {
    const { REACT_APP_ApplicationId } = process.env;
    const lucHeaders = {
      Authorization: `Bearer ${getLocalStorageItem('access_token')}`,
      'Application-Id': REACT_APP_ApplicationId,
    };
    const res = await getLUCUserDetails(lucHeaders);
    return res.data;
  } catch (err) {
    return err;
  }
}

async function fbGetLocations(value) {
  try {
    const fbGetLoc = await fbGetLocation(value);
    return fbGetLoc.data;
  } catch (e) {
    return Promise.reject(e);
  }
}
async function fbGetAdLocations(value) {
  try {
    const fbGetAdLoc = await fbAdGeolocation(value);
    return fbGetAdLoc.data;
  } catch (e) {
    return Promise.reject(e);
  }
}
async function fbGetFriends() {
  try {
    const fbGetLoc = await fbGetFriendList();
    return fbGetLoc.data;
  } catch (e) {
    return Promise.reject(e);
  }
}

async function fbAdinterest(value) {
  try {
    const fbGetLoc = await fbAdTags(value);
    return fbGetLoc.data;
  } catch (e) {
    return Promise.reject(e);
  }
}
async function fbGetCrossPage(pageId, token) {
  try {
    const fbGetPage = await fbCrossPages(pageId, token);
    return fbGetPage.data.data;
  } catch (e) {
    return Promise.reject(e);
  }
}
async function fbPostCrossPages(id1, id2, stat, token) {
  try {
    const fbPostPage = await initCrossPages(id1, id2, stat, token);
    return fbPostPage.data;
  } catch (e) {
    return Promise.reject(e);
  }
}
async function fbCreateLiveVideo(id, token, payload) {
  try {
    const fbGetPage = await createLiveVideo(id, token, payload);
    return fbGetPage.data;
  } catch (e) {
    return Promise.reject(e);
  }
}
async function fbUpdateLiveVideo(id, token, payload) {
  try {
    const fbGetPage = await updateLiveVideo(id, token, payload);
    return fbGetPage.data;
  } catch (e) {
    return Promise.reject(e);
  }
}
async function fbGetLiveVideoStatus(id, accessToken) {
  try {
    const fbData = await GetLiveVideoStatus(id, accessToken);
    return fbData.data;
  } catch (e) {
    return Promise.reject(e);
  }
}
async function fbUpdateLiveVideoStatus(id, payload, accessToken) {
  try {
    const fbData = await UpdateLiveVideoStatus(id, payload, accessToken);
    return fbData.data;
  } catch (e) {
    return Promise.reject(e);
  }
}

function fbGetPostViews(id, token, field) {
  const FBField = field;
  return new Promise(async (resolve, reject) => {
    try {
      const fbData = await GetPost(id, token, field);
      if (FBField === 'live_views') {
        resolve(fbData.data.live_views);
      } else {
        resolve(fbData.data);
      }
    } catch (e) {
      reject(e);
    }
  });
}

function fbGetPostReactions(id, token) {
  return new Promise(async (resolve, reject) => {
    try {
      const fbData = await GetPostReactions(id, token);
      resolve(fbData.data.data);
    } catch (e) {
      reject(e);
    }
  });
}

function fbGetPostComments(id, token) {
  return new Promise(async (resolve, reject) => {
    try {
      const fbData = await GetPostComments(id, token);
      resolve(fbData.data.data);
    } catch (e) {
      reject(e);
    }
  });
}

function fbGetUserDetails(token) {
  return new Promise(async (resolve, reject) => {
    try {
      const fbUserData = await getFacebookUserDetails(token);
      resolve(fbUserData.data);
    } catch (e) {
      reject(e);
    }
  });
}

async function fbGetGeoupByPageId(pageId, token) {
  try {
    const fbGetPage = await fbGetGroupByPage(pageId, token);
    return fbGetPage.data;
  } catch (e) {
    return Promise.reject(e);
  }
}

async function fbGetPageTokenByPageId(pageId) {
  try {
    const fbGetPage = await fbGetPageToken(pageId);
    return fbGetPage.data;
  } catch (e) {
    return Promise.reject(e);
  }
}

async function fbImageUpload(payload) {
  try {
    const awsHeaders = {
      Authorization: `Bearer ${getLocalStorageItem('access_token')}`,
      'x-user-uuid': `${cookies.get('user_session')}`,
    };
    const imgUrl = await ImageUploadS3(awsHeaders, payload);
    return imgUrl.data;
  } catch (e) {
    return Promise.reject(e);
  }
}
