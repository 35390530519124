import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { Row, Col, Form, FormGroup, Input, Button, Label } from 'reactstrap';
import { LangDropdown } from '../LangDropdown';
import { Formik, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import GoSoloBlack from '../../assets/images/go-solo-black.png';
import './Register.css';

export const RegisterForm = (props) => {
  const {
    t,
    history,
    submitted,
    onSubmitHandler,
    changeCommunicationPreference,
    preference,
    registeredSuccess,
  } = props;

  // function showpass1() {
  //   var x = document.getElementById('txtPassword');
  //   var y = document.getElementById('fontIcon1')

  //   if (x.type === 'password') {
  //     x.type = 'text';
  //     y.className = 'fa fa-eye';
  //   } else {
  //     x.type = 'password';
  //     y.className = 'fa fa-eye-slash';
  //   }
  // }
  // function showpass2() {
  //   var x = document.getElementById('txtConfirmPassword');
  //   var y = document.getElementById('fontIcon2')

  //   if (x.type === 'password') {
  //     x.type = 'text';
  //     y.className = 'fa fa-eye';
  //   } else {
  //     x.type = 'password';
  //     y.className = 'fa fa-eye-slash';
  //   }
  // }

  return (
    <div className='entrySection d-flex align-items-center justify-content-center'>
      <div className='entryContainer'>
        <div className='loginLang'>
          <LangDropdown align={false} />
        </div>
        <Row className='rowflex d-flex flex-row align-items-stretch'>
          <Col
            md='7'
            className='d-none d-md-flex whitebgTransparent rightSide align-items-center '
          >
            <span className='titleTxt'>
              {t('MAKING')} <br />
              {t('LIVE')} <br />
              {t('SIMPLER')}
            </span>
          </Col>
          <Col
            md='5'
            className='leftSide registation  d-flex flex-column justify-content-center'
          >
            <div className='welcome'>
              <div className='text-center mb-0'>
                <img src={GoSoloBlack} alt='description' />
              </div>
              <h4 className='text-center mt-0 mb-1'>{t('WELCOME')}</h4>
              {!registeredSuccess && <h5>{t('REGISTER')}</h5>}
              {registeredSuccess && (
                <div className='card-body'>
                  <p className='text-center success-register'>
                    {t('THANKYOUFORREGISTERING')}
                  </p>
                  <div className='regInput w-100 text-center login-links-register'>
                    <button
                      type='button'
                      className='atag'
                      onClick={() => history.push('/login')}
                    >
                      {t('GOBACK')}
                    </button>
                  </div>
                </div>
              )}
            </div>
            <div className='errormsg'>
              <span id='errormsg' className='text-center'></span>
            </div>
            {!registeredSuccess && (
              <Formik
                initialValues={{
                  fname: '',
                  lname: '',
                  email: '',
                  password: '',
                  confirmpassword: '',
                  tnc: false,
                  captchaValue: '',
                }}
                validationSchema={Yup.object({
                  fname: Yup.string()
                    .trim()
                    .min(3, t('FNAMECHARACTERS'))
                    .required(t('FNAMEREQUIRED')),
                  lname: Yup.string()
                    .trim()
                    .min(3, t('LNAMECHARACTERS'))
                    .required(t('LNAMEREQUIRED')),
                  email: Yup.string()
                    .trim()
                    .required(t('EMAILREQUIRED'))
                    .matches(
                      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,5}$/,
                      t('INVALIDEMAIL'),
                    ),
                  password: Yup.string()
                    .trim()
                    .required(t('PASSWORDREQUIRED'))
                    .matches(
                      // eslint-disable-next-line no-useless-escape
                      /^(?=.*[!#$@’+,?\[\]._-])(?=.*[a-z])(?=.*[A-Z])[\w!#$@’+,?\[\]._-]{8,128}$|(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])[\w!#$@’+,?\[\]._-]{8,128}$/,
                      t('PASSWORDCHARACTERS'),
                    ),
                  confirmpassword: Yup.string()
                    .trim()
                    .oneOf([Yup.ref('password'), null], t('PASSSWORDONOTMATCH'))
                    .required(t('PASSWORDREQUIRED')),
                  tnc: Yup.boolean(),
                  captchaValue: Yup.string().required(),
                })}
                // eslint-disable-next-line no-unused-vars
                onSubmit={(values, { setSubmitting }) => {
                  values.fname = values.fname.trim();
                  values.lname = values.lname.trim();
                  values.email = values.email.trim();
                  values.password = values.password.trim();
                  values.confirmpassword = values.confirmpassword.trim();
                  onSubmitHandler(values);
                }}
                // enableReinitialize={true}
              >
                {(formik) => (
                  <Form
                    data-testid='registerForm'
                    name='register'
                    className='w-100'
                    onSubmit={formik.handleSubmit}
                    noValidate
                  >
                    {!registeredSuccess && (
                      <React.Fragment>
                        <FormGroup className='regInput'>
                          <Input
                            className={`capitalize ${
                              formik.errors.fname ? '' : 'success'
                            }`}
                            placeholder={t('FNAME')}
                            name='fname'
                            type='text'
                            maxLength='255'
                            autoFocus
                            {...formik.getFieldProps('fname')}
                          />
                          <span
                            className={
                              !formik.errors.fname && formik.values.fname
                                ? 'successtick'
                                : 'd-none'
                            }
                          >
                            <i className='fa fa-check' />
                          </span>
                          <ErrorMessage
                            component='span'
                            name='fname'
                            className='col-sm-8 col-sm-push-4 error'
                          />
                        </FormGroup>
                        <FormGroup className='regInput'>
                          <Input
                            className={`capitalize ${
                              formik.errors.lname ? '' : 'success'
                            }`}
                            placeholder={t('LNAME')}
                            name='lname'
                            type='text'
                            maxLength='255'
                            {...formik.getFieldProps('lname')}
                          />
                          <span
                            className={
                              !formik.errors.lname && formik.values.lname
                                ? 'successtick'
                                : 'd-none'
                            }
                          >
                            <i className='fa fa-check' />
                          </span>
                          <ErrorMessage
                            component='span'
                            name='lname'
                            className='col-sm-8 col-sm-push-4 error'
                          />
                        </FormGroup>
                        <FormGroup className='regInput'>
                          <Input
                            className={`form-control ${
                              formik.errors.email ? '' : 'success'
                            }`}
                            placeholder={t('EMAILADDRESS')}
                            name='email'
                            id='txtEmail'
                            type='email'
                            maxLength='75'
                            {...formik.getFieldProps('email')}
                          />
                          <span
                            className={
                              !formik.errors.email && formik.values.email
                                ? 'successtick'
                                : 'd-none'
                            }
                          >
                            <i className='fa fa-check' />
                          </span>
                          <ErrorMessage
                            component='span'
                            name='email'
                            className='col-sm-8 col-sm-push-4 error'
                          />
                        </FormGroup>
                        <FormGroup className='regInput'>
                          {/* <div className='input-group'> */}
                          <Input
                            className={`form-control ${
                              formik.errors.password ? '' : 'success'
                            }`}
                            placeholder={t('PASSWORD')}
                            id='txtPassword'
                            name='password'
                            type='password'
                            maxLength='128'
                            {...formik.getFieldProps('password')}
                          />
                          <span
                            className={
                              !formik.errors.password && formik.values.password
                                ? 'successtick'
                                : 'd-none'
                            }
                          >
                            <i className='fa fa-check' />
                          </span>
                          {/* <div className='input-group-addon'>
                              <i
                                className='fa fa-eye-slash'
                                id='fontIcon1'
                                onClick={() => showpass1()}
                              />
                            </div>
                          </div> */}
                          <ErrorMessage
                            component='span'
                            name='password'
                            className='col-sm-8 col-sm-push-4 error'
                          />
                        </FormGroup>
                        <FormGroup className='regInput'>
                          {/* <div className='input-group'> */}
                          <Input
                            className={`form-control ${
                              formik.errors.confirmpassword ? '' : 'success'
                            }`}
                            placeholder={t('REENTERPASSWORD')}
                            id='txtConfirmPassword'
                            name='confirmpassword'
                            type='password'
                            maxLength='128'
                            {...formik.getFieldProps('confirmpassword')}
                          />

                          <span
                            className={
                              !formik.errors.confirmpassword &&
                              formik.values.confirmpassword
                                ? 'successtick'
                                : 'd-none'
                            }
                          >
                            <i className='fa fa-check' />
                          </span>
                          {/* <div className='input-group-addon'>
                              <i
                                className='fa fa-eye-slash'
                                id='fontIcon2'
                                onClick={() => showpass2()}
                              />
                            </div>
                            
                          </div> */}
                          <ErrorMessage
                            component='span'
                            name='confirmpassword'
                            className='col-sm-8 col-sm-push-4 error'
                          />
                        </FormGroup>
                        <FormGroup className='regInput iamrobot'>
                          <ReCAPTCHA
                            sitekey='6LfvgBITAAAAAKZZSXVrhPvFceB3mARvJLtOFZB9'
                            onChange={(value) => {
                              formik.setFieldValue('captchaValue', value);
                              formik.values.captchaValue = value;
                            }}
                          />
                        </FormGroup>
                        <FormGroup className='regInput'>
                          <div className='d-flex align-items-center'>
                            <label className='regInputCheck customCheckBox'>
                              <input
                                type='checkbox'
                                className={'checkbox-row'}
                                checked={preference}
                                onClick={changeCommunicationPreference}
                                id={'communicationPreference'}
                              />{' '}
                              <span
                                className='d-inline-flex'
                                htmlFor={'communicationPreference'}
                              >
                                <p>{t('COMMUNICATIONMESSAGE')}</p>
                              </span>
                            </label>
                          </div>
                          <div className='d-flex align-items-center'>
                            <Label
                              className={`customCheckBox ${
                                formik.values.tnc ? 'selected' : ''
                              }`}
                              onChange={() => {
                                formik.setFieldValue('tnc', !formik.values.tnc);
                                formik.values.tnc = !formik.values.tnc;
                              }}
                              check
                            >
                              <Input
                                type='checkbox'
                                id='checkbox2'
                                name='tnc'
                                className='checkbox-row'
                              />
                              <span>{t('IAGREE')} &nbsp;</span>
                            </Label>
                            <button
                              type='button'
                              className='btag'
                              onClick={() => {
                                window.open('/soloterms', '_blank');
                              }}
                            >
                              {t('TNC')}
                            </button>
                          </div>
                        </FormGroup>
                        <FormGroup className='regInput'>
                          {submitted ? (
                            <Button
                              type='button'
                              id='btnLoading'
                              value={t('LOADING')}
                              className='btn btn-lg btn-danger btn-block'
                            >
                              LOADING
                            </Button>
                          ) : (
                            <Button
                              type='submit'
                              id='btnLogin'
                              value={t('SUBMIT')}
                              className='btn btn-lg btn-primary btn-block'
                              disabled={!formik.isValid || !formik.values.tnc}
                            >
                              SUBMIT
                            </Button>
                          )}
                        </FormGroup>
                        <FormGroup className='regInput w-100 text-right mb-2 login-links-register'>
                          <button
                            type='button'
                            className='atag'
                            onClick={() => history.push('/login')}
                          >
                            {t('GOBACK')}
                          </button>
                        </FormGroup>
                      </React.Fragment>
                    )}
                  </Form>
                )}
              </Formik>
            )}
          </Col>
        </Row>
      </div>
    </div>
  );
};

//Local captcha key - 6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI
