import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';
import { Template } from './components/Template';
import { ytService } from './services/editunit/yt.service';
import Rollbar from 'rollbar';
import './App.css';
import { initFBSdk } from './services/fbSdk.service';
import { notify } from './components/CommonNotification/CommonNotification';
import i18n from 'i18next';
import Cookies from 'universal-cookie';
// import ReactGA from 'react-ga';
const cookies = new Cookies();

const {
  REACT_APP_googleApiKey,
  REACT_APP_googleClientId,
  REACT_APP_googleScope,
  REACT_APP_environment,
  // REACT_APP_googleAnalyticsID,
} = process.env;

// ReactGA.initialize(REACT_APP_googleAnalyticsID);
class App extends Component {
  componentDidMount() {
    initFBSdk();
    initGoogleSdk();
    const rollbarObj = !!window.rollbarConfig
      ? new Rollbar(window.rollbarConfig)
      : null;
    if (!!rollbarObj) {
      window.rollbarObj = rollbarObj;
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location !== this.props.location) {
      let d = new Date();
      d.setTime(d.getTime() + 1 * 24 * 60 * 60 * 1000);
      cookies.set('last_visited_url', this.props.location.pathname, {
        path: '/',
        expires: d,
        secure: true,
      });
    }
  }

  render() {
    // window.dataLayer.push({
    //   event: 'pageview',
    // });
    return (
      <div className='App'>
        <Switch>
          <Route
            exact
            path='/'
            render={() => <Redirect to='/dashboard/units' />}
          />
          <Route path='/dashboard' component={Template} />
          {REACT_APP_environment !== 'development' && (
            <Route
              exact
              path='*'
              render={() => <Redirect to='/dashboard/units' />}
            />
          )}
        </Switch>
      </div>
    );
  }
}
export const initGoogleSdk = () => {
  try {
    function start() {
      // Initialize the JavaScript client library.
      // eslint-disable-next-line no-undef
      gapi.client
        .init({
          apiKey: REACT_APP_googleApiKey,
          // clientId and scope are optional if auth is not required.
          clientId: REACT_APP_googleClientId,
          scope: REACT_APP_googleScope,
        })
        .then(function () {
          // Listen for sign-in state changes.
          ytService.listenYTSignInChange();
          ytService.getYTSignInStatus();
        });
    }

    //Load the JavaScript client library.
    // eslint-disable-next-line no-undef
    gapi.load('client:auth2', start);
  } catch (e) {
    notify('warning', i18n.t('GAPIFAIL'));
  }
};
const mapStateToProps = (state) => ({
  loggedIn: state.authentication.loggedIn,
});

export default connect(mapStateToProps, null)(App);
